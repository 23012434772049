
    import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
    import FilterContentItem from '@/components/selector/filter/FilterContentItem.vue';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import BrandIcon from '@/components/selector/filter/BrandIcon.vue';
    import { Brand } from '@/enums/Brand';
    import { VehicleClass } from '@/enums/VehicleClass';
    import { VehicleType } from '@/enums/VehicleType';
    import { Seat } from '@/enums/Seat';
    import { Drive } from '@/enums/Drive';
    import InfoModal from '@/components/configurator/InfoModal.vue';
    import Tooltip from '@/components/configurator/Tooltip.vue';
    import { IRangeItem } from '@/interfaces/IRangeItem';
    import { Country } from '@/enums/Country';
    import { HitchType } from '@/enums/Hitch';
    import { AvailabilitySteps } from '@/enums/AvailabilitySteps';
    import { isQuizResult } from '@/util/queryfilter';
    import { useGlobalStore } from '@/store/global';
    import { useSelectorStore } from '@/store/selector';
    import { VehicleState } from '@/enums/VehicleState';

    @Component({
        components: {
            BrandIcon,
            FilterContentItem,
            CircleTag,
            InfoModal,
            Tooltip,
        },
    })
    export default class FilterContent extends Vue {
        @Prop() carCount!: number;
        brands: any;
        vehicleClasses: any;
        vehicleTypes: any;
        drives: any;
        hitches: any;
        availabilitySteps: any;
        country: any;
        quizResult = false;

        seatList!: Seat[];
        priceList: IRangeItem[] = [
            { from: 0, to: 200 },
            { from: 0, to: 400 },
            { from: 0, to: 600 },
            { from: 0, to: 800 },
        ];
        rangeList: IRangeItem[] = [
            { from: 300, to: Number.MAX_SAFE_INTEGER },
            { from: 400, to: Number.MAX_SAFE_INTEGER },
            { from: 500, to: Number.MAX_SAFE_INTEGER },
            { from: 600, to: Number.MAX_SAFE_INTEGER },
        ];
        chargeTimeList: IRangeItem[] = [
            { from: 0, to: 20 },
            { from: 0, to: 30 },
            { from: 0, to: 40 },
            { from: 0, to: 50 },
        ];
        chargeRateList: IRangeItem[] = [
            { from: 50, to: Number.MAX_SAFE_INTEGER },
            { from: 100, to: Number.MAX_SAFE_INTEGER },
            { from: 150, to: Number.MAX_SAFE_INTEGER },
            { from: 200, to: Number.MAX_SAFE_INTEGER },
        ];
        accelerationList: IRangeItem[] = [
            { from: 0, to: 4 },
            { from: 0, to: 6 },
            { from: 0, to: 8 },
            { from: 0, to: 10 },
        ];

        get usedSeats() {
            return this.seatList.filter((item) => this.isUsedSeat(item));
        }

        get globalStore() {
            return useGlobalStore();
        }

        get selectorStore() {
            return useSelectorStore();
        }

        get hasUsedCars() {
            if (this.selectorStore.allCars) {
                return this.selectorStore.allCars?.find(
                    (car) => car.vehicle_state === VehicleState.USED_CAR,
                );
            }

            return false;
        }

        get hasYoungUsedCars() {
            if (this.selectorStore.allCars) {
                return this.selectorStore.allCars?.find(
                    (car) => car.vehicle_state === VehicleState.JUNGWAGEN,
                );
            }

            return false;
        }

        get hasTrialSubscriptionCars() {
            if (this.selectorStore.allCars) {
                return this.selectorStore.allCars?.find((car) => car.offer_trialsubscription);
            }

            return false;
        }

        isUsedBrand(currentBrand: Brand) {
            // look for current brand in used brand list (from store)
            return this.globalStore.brands.find((b) => b.id === currentBrand.valueOf());
        }

        isUsedVehicleClass(currentVehicleClass: VehicleClass) {
            // look for current car class in used car class list (from store)
            return this.globalStore.vehicleClasses.find(
                (c) => c.id === currentVehicleClass.valueOf(),
            );
        }

        isUsedVehicleType(currentVehicleType: VehicleType) {
            // look for current brand in used brand list (from store)
            return this.globalStore.vehicleTypes.find((t) => t.id === currentVehicleType.valueOf());
        }

        isUsedSeat(seat: number) {
            if (this.selectorStore.allCars) {
                return this.selectorStore.allCars?.find(
                    (car) =>
                        car.technicaldata.seats_standard == seat ||
                        car.technicaldata.seats_optional == seat,
                );
            }

            return true;
        }

        /**
         * Event handler for changing the availability
         *
         * @param a the availibility steps
         * @param event
         */
        @Emit('filterChanged')
        availabilityClicked(a: AvailabilitySteps, event: Event) {
            if (this.selectorStore.filterParameter.availability == a) {
                this.selectorStore.filterParameter.availability = AvailabilitySteps.ALL;
            } else {
                this.selectorStore.filterParameter.availability = a;
            }
        }

        hasSeat(seat: number) {
            return this.selectorStore.filterParameter.seats.includes(seat);
        }

        /**
         * Event handler for clicking a 'seat circle'.
         *
         * @param n the number of seats
         * @param event
         */
        @Emit('filterChanged')
        seatsClicked(n: number, event: Event) {
            if (this.hasSeat(n)) {
                this.selectorStore.removeFilterSeat(n);
            } else {
                this.selectorStore.appendFilterSeat(n);
            }
        }

        areEqualRanges(r1: IRangeItem | undefined, r2: IRangeItem | undefined): boolean {
            if (!r1 || !r2) {
                return false;
            }

            return r1.to === r2.to && r1.from === r2.from;
        }

        hasDrive(drive: number): boolean {
            return this.selectorStore.filterParameter.drives.includes(drive);
        }

        hasBrand(brand: Brand): boolean {
            return this.selectorStore.filterParameter.brands.includes(brand);
        }

        hasVehicleClass(vehicleClass: VehicleClass): boolean {
            return this.selectorStore.filterParameter.vehicleClasses.includes(vehicleClass);
        }

        hasVehicleType(vehicleType: VehicleType): boolean {
            return this.selectorStore.filterParameter.vehicleTypes.includes(vehicleType);
        }

        /**
         * Event handler for clicking a 'range' item.
         *
         * @param r the clicked 'RangeItem'
         * @param event
         */
        @Emit('filterChanged')
        rangeClicked(r: IRangeItem, event: Event) {
            if (this.areEqualRanges(this.selectorStore.filterParameter.rangeItem, r)) {
                // active range clicked, so reset it!
                this.selectorStore.filterParameter.rangeItem = undefined;
            } else {
                this.selectorStore.filterParameter.rangeItem = r;
            }
        }

        /**
         * Event handler for clicking a 'price' item.
         *
         * @param r the clicked 'RangeItem'
         * @param event
         */
        @Emit('filterChanged')
        priceClicked(p: IRangeItem, event: Event) {
            if (this.areEqualRanges(this.selectorStore.filterParameter.priceItem, p)) {
                // active price clicked, so reset it!
                this.selectorStore.filterParameter.priceItem = undefined;
            } else {
                this.selectorStore.filterParameter.priceItem = p;
            }
        }

        /**
         * Event handler for clicking a 'charge time' item.
         *
         * @param c the clicked Item
         * @param event
         */
        @Emit('filterChanged')
        chargeTimeClicked(c: IRangeItem, event: Event) {
            if (this.areEqualRanges(this.selectorStore.filterParameter.chargeTimeDcItem, c)) {
                // active range clicked, so reset it!
                this.selectorStore.filterParameter.chargeTimeDcItem = undefined;
            } else {
                this.selectorStore.filterParameter.chargeTimeDcItem = c;
            }
        }

        /**
         * Event handler for clicking a 'charge rate' item.
         *
         * @param c the clicked Item
         * @param event
         */
        @Emit('filterChanged')
        chargeRateClicked(c: IRangeItem, event: Event) {
            if (this.areEqualRanges(this.selectorStore.filterParameter.chargeRateDcMaxItem, c)) {
                // active range clicked, so reset it!
                this.selectorStore.filterParameter.chargeRateDcMaxItem = undefined;
            } else {
                this.selectorStore.filterParameter.chargeRateDcMaxItem = c;
            }
        }

        /**
         * Event handler for clicking a 'acceleration' item.
         *
         * @param a the clicked acceleration item
         * @param event
         */
        @Emit('filterChanged')
        accelerationClicked(a: IRangeItem, event: Event) {
            if (this.areEqualRanges(this.selectorStore.filterParameter.accelerationItem, a)) {
                // active range clicked, so reset it!
                this.selectorStore.filterParameter.accelerationItem = undefined;
            } else {
                this.selectorStore.filterParameter.accelerationItem = a;
            }
        }

        /**
         * Event handler for clicking a 'drive' item.
         *
         * @param d the clicked drive item
         * @param event
         */
        @Emit('filterChanged')
        driveClicked(d: Drive, event: Event) {
            if (this.hasDrive(d)) {
                // active range clicked, so reset it!
                this.selectorStore.removeFilterDrive(d);
            } else {
                this.selectorStore.appendFilterDrive(d);
            }
        }

        /**
         * Event handler for clicking a 'hitch' item.
         *
         * @param h the clicked hitchtype item
         * @param event
         */
        @Emit('filterChanged')
        hitchClicked(h: HitchType, event: Event) {
            if (this.selectorStore.filterParameter.hitch == h) {
                // active range clicked, so reset it!
                this.selectorStore.filterParameter.hitch = undefined;
            } else {
                this.selectorStore.filterParameter.hitch = h;
            }
        }

        @Emit('filterChanged')
        newVehiclesClicked() {
            if (this.selectorStore.filterParameter.misc.new) {
                this.selectorStore.filterParameter.misc.new = false;
            } else {
                this.selectorStore.filterParameter.misc.new = true;
            }
        }

        @Emit('filterChanged')
        usedVehiclesClicked() {
            if (this.selectorStore.filterParameter.misc.used) {
                this.selectorStore.filterParameter.misc.used = false;
            } else {
                this.selectorStore.filterParameter.misc.used = true;
            }
        }

        @Emit('filterChanged')
        usedYoungVehiclesClicked() {
            if (this.selectorStore.filterParameter.misc.usedYoung) {
                this.selectorStore.filterParameter.misc.usedYoung = false;
            } else {
                this.selectorStore.filterParameter.misc.usedYoung = true;
            }
        }

        /**
         *  Add a brand to filter parameters.
         *
         * @param b
         * @param event
         */
        @Emit('filterChanged')
        brandClicked(b: Brand, event: Event) {
            if (this.hasBrand(b)) {
                this.selectorStore.removeFilterBrand(b);
            } else {
                this.selectorStore.appendFilterBrand(b);
            }
        }

        /**
         *  Add a vehicle class to filter parameters.
         *
         * @param c
         * @param event
         */
        @Emit('filterChanged')
        vehicleClassClicked(c: VehicleClass, event: Event) {
            if (this.hasVehicleClass(c)) {
                this.selectorStore.removeFilterVehicleClass(c);
            } else {
                this.selectorStore.appendFilterVehicleClass(c);
            }
        }

        /**
         *  Add a vehicle type to filter parameters.
         *
         * @param t
         * @param event
         */
        @Emit('filterChanged')
        vehicleTypeClicked(t: VehicleType, event: Event) {
            if (this.hasVehicleType(t)) {
                this.selectorStore.removeFilterVehicleType(t);
            } else {
                this.selectorStore.appendFilterVehicleType(t);
            }
        }

        /**
         * Build the displayed seat list.
         */
        buildSeatList() {
            const keys = Object.keys(Seat).filter((k) => typeof Seat[k as any] === 'number');
            const values = keys.map((k) => Seat[k as any]);
            const l: number[] = [];

            for (const v of values) {
                l.push(parseInt(v, 10));
            }

            return l;
        }

        beforeCreate() {
            this.brands = Brand;
            this.vehicleClasses = VehicleClass;
            this.vehicleTypes = VehicleType;
            this.drives = Drive;
            this.hitches = HitchType;
            this.availabilitySteps = AvailabilitySteps;
            this.country = Country;
        }

        mounted() {
            // Open all filter items if quiz query is set
            this.quizResult = isQuizResult(this.$route);
        }

        created() {
            this.seatList = this.buildSeatList();
        }
    }
