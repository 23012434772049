
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import FilterContent from '@/components/selector/filter/FilterContent.vue';
    import FilterActions from '@/components/selector/filter/FilterActions.vue';
    import { useSelectorStore } from '@/store/selector';

    @Component({
        components: {
            FilterContent,
            FilterActions,
        },
    })
    export default class FilterOffCanvas extends Vue {
        @Prop() carCount!: number;

        get selectorStore() {
            return useSelectorStore();
        }

        /**
         * Clear the filter and apply default values and hide filter off-canvas.
         */
        onDeleteFilter() {
            this.$emit('deleteFilter');
        }

        onFilterChanged() {
            this.$emit('filterChanged');
        }
    }
