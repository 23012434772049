
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import i18n from '@/i18n';
    import { useSelectorStore } from '@/store/selector';

    @Component
    export default class FilterActions extends Vue {
        @Prop() carCount!: number;

        get selectorStore() {
            return useSelectorStore();
        }

        get filterButtonText(): string {
            if (this.carCount === 0 || this.carCount === 1) {
                return i18n.tc('messages.filter_button', this.carCount);
            }

            return i18n.tc('messages.filter_button', this.carCount, { count: this.carCount });
        }
    }
