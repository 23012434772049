
    import { Prop, Vue, Component } from 'vue-property-decorator';
    import CircleTag from '@/components/tags/CircleTag.vue';
    import { normalizeSvgViewBoxes } from '@/util/misc';

    @Component({
        components: {
            CircleTag,
        },
    })
    export default class BrandIcon extends Vue {
        @Prop({ default: '' }) iconName!: string;
        @Prop({ default: false }) isActive!: boolean;

        get iconPath() {
            return require(`@/assets/img/brand-icons/${this.iconName}.svg`);
        }

        brandIconClicked() {
            this.$emit('onBrandClicked');
        }

        mounted() {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                normalizeSvgViewBoxes(this.$el.querySelectorAll('.brand-icon > svg'));
            });
        }
    }
