export enum VehicleType {
    SCHRAEGHECK = 1,
    SUV = 2,
    LIMOUSINE = 3,
    KOMBI = 4,
    COUPE = 5,
    CABRIO = 6,
    VAN = 7,
    KASTENWAGEN = 8,
    PICKUP = 9,
    CROSSOVER = 10,
    SUV_COUPE = 11,
}
